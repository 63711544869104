import image1 from "../assets/t-image1.png";
import image2 from "../assets/t-image2.jpg";
import image3 from "../assets/t-image3.jpg";

export const testimonialsData = [
  {
    image: image1,
    review:
      "I made the right choice by choosing the Fitclub and by choosing the right plan and program I already achieved my ideal body!",
    name: 'MD TABREZ',
    status : 'DEVELOPER'
  },
  {
    image: image2,
    review: 'When Life Gives You Pain,GO TO THE GYM - The FitClub',
    name: 'Virus',
    status: 'Designer'
  },
  {
    image : image3,
    review:'“ITS A VERY CLEAN, LOW KEY GYM. FOR ME IT IS CLOSE TO WORK AND I LIKE THE CROWD.”',
    name: 'Mr.A_Wali',
    status: "CUSTOMER"
  }
];
